<template>
  <div>
    <v-card class="nospace">
      <v-card-title class="nospace bg-clr">
        <v-layout align-center>
          <v-flex xs2 md2 style="margin-top: 10px; margin-left: 2px">
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 style="margin-top: 11px">
            <v-text-field
              height="55px"
              class="gap inputSearch"
              label="Search here.."
              type="number"
              outline
              :disabled="!searchTypes"
              single-line
              v-model="searchText"
              @keyup.enter="searchAnything"
              @click:append="searchAnything"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 md3 pl-2 pr-2>
            <v-autocomplete
              v-model="select"
              :items="AssignmentStatus"
              item-text="status"
              item-value="abbr"
              multiple
              label="Filter by Assignment Status "
              single-line
              @change="filterByAssignmentStatus"
            ></v-autocomplete>
          </v-flex>
          <v-flex
            xs3
            pl-3
            style="padding-top: 2px"
            class="wrapper"
            id="jobDate"
          >
            <ejs-daterangepicker
              :placeholder="waterMark"
              v-model="dateRange2"
            ></ejs-daterangepicker>
          </v-flex>
          <v-flex xs1>
            <v-card-actions class="justify-center">
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="nospace">
        <v-data-table
          id="paymentsTable"
          class="word___Break"
          no-data-text="No Assignments given so far"
          hide-actions
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <td style="width: 5% !important">
              {{ props.item.jobId }}<br />{{ getTime(props.item.startDate) }}
            </td>
            <td>
              {{ props.item.assignmentId }}<br />
              {{ assignmentStatus(props.item.assignmentStatus) }}
            </td>
            <td>
              {{ props.item.ownerType }} <br />
              <span v-if="props.item.vendorName">{{
                props.item.vendorName
              }}</span>
              <span v-else>
                {{
                  props.item.transporterData
                    ? props.item.transporterData.transporterName
                    : "N.A"
                }}
              </span>
            </td>

            <td style="width: 10% !important">
              {{
                getLocation(props.item.sourceLocation, props.item.sourceCity)
              }}
            </td>
            <td style="width: 10% !important">
              {{
                getLocation(
                  props.item.destinationLocation,
                  props.item.destinationCity
                )
              }}
            </td>
            <td v-if="props.item">
              <div v-if="props.item.truckPrefix">
                {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
                <span v-if="props.item.truckType"
                  ><br />
                  ({{ props.item.truckType }})</span
                >
              </div>
              <div v-else>-</div>
            </td>
            <td>
              {{
                props.item.paymentAmount
                  ? props.item.paymentAmount + " " + props.item.paymentCurrency
                  : "-"
              }}
            </td>
            <td>
              {{
                props.item.totalAdditionalAmount
                  ? props.item.totalAdditionalAmount +
                    " " +
                    props.item.paymentCurrency
                  : "-"
              }}
            </td>
            <td>
              {{
                props.item.totalAdvancePaidAmount
                  ? props.item.totalAdvancePaidAmount +
                    " " +
                    props.item.paymentCurrency
                  : "-"
              }}
            </td>

            <td>
              <div v-if="props.item.wayBillNo">
                <div class="text-center v-btn v-btn--flat v-btn--router">
                  <!-- USERS -->
                  <v-menu
                    transition="slide-x-transition"
                    offset-y
                    class="profile-drop"
                  >
                    <v-toolbar-side-icon slot="activator">
                      <v-icon>receipt</v-icon>
                    </v-toolbar-side-icon>
                    <v-list>
                      <v-list-tile
                        v-if="countrySortName != 'PAK'"
                        :to="`/assignments/${props.item.assignmentId}/UAE/waybill`"
                        target="_blank"
                        @click="selectActive(26)"
                        :color="
                          index === 26 ? 'orange darken-3' : 'grey darken-3'
                        "
                      >
                        <v-list-tile-title>UAE</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="countrySortName != 'PAK'"
                        :to="`/assignments/${props.item.assignmentId}/UAE/waybill`"
                        target="_blank"
                        @click="selectActive(26)"
                        :color="
                          index === 26 ? 'orange darken-3' : 'grey darken-3'
                        "
                      >
                        <v-list-tile-title>UAE LA</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="countrySortName != 'PAK'"
                        :to="`/assignments/${props.item.assignmentId}/UAE/waybill`"
                        target="_blank"
                        @click="selectActive(26)"
                        :color="
                          index === 26 ? 'orange darken-3' : 'grey darken-3'
                        "
                      >
                        <v-list-tile-title>KSA</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="countrySortName != 'PAK'"
                        :to="`/assignments-old/${props.item.assignmentId}/KSA/waybill`"
                        target="_blank"
                        @click="selectActive(26)"
                        :color="
                          index === 26 ? 'orange darken-3' : 'grey darken-3'
                        "
                      >
                        <v-list-tile-title>KSA Old</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="countrySortName == 'PAK'"
                        :to="`/assignments/${props.item.assignmentId}/PAK/waybill`"
                        target="_blank"
                        @click="selectActive(26)"
                        :color="
                          index === 26 ? 'orange darken-3' : 'grey darken-3'
                        "
                      >
                        <v-list-tile-title>PAK</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div v-else>-</div>
            </td>
            <!-- <td class="justify-center px-0">
              <v-btn
                v-if="props.item.assignmentStatus === 3"
                style="background: orange !important"
                flat
                prepend-icon
                color="white darken-1"
                @click.native="setAssignment(props.item)"
              >
                Make Payment
              </v-btn>
            </td> -->
            <td>
              <ProofOfDeliveryForDriverBooking
                :assignmentId="props.item._id"
                :status="props.item.assignmentStatus"
                @error="error = 'No proof'"
              />
            </td>
            <td class="">
              <v-btn
                flat
                prepend-icon
                color="blue darken-1"
                @click.native="goToProfile(props.item.jobId)"
              >
                View Detail <v-icon color="blue">arrow_right</v-icon>
              </v-btn>
            </td>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
          >
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
        <span class="page-count-span totalpages"
          >Total Pages - {{ totalPages }}</span
        >
        <PaginationButtons
          :url="`/dmsAdmin/driverAssignment`"
          :callback="callback"
          :payload="payload"
          v-on:pagination-payload="paginationPayload"
        />
      </v-card-text>
    </v-card>

    <div v-if="selectedAssignment">
      <payment-dialog
        :show="paymentDialog"
        :onClose="() => (paymentDialog = false)"
        :assignmentId="selectedAssignment.assignmentId"
        :onConfirm="makePayment"
        :paymentAmount="selectedAssignment.paymentAmount"
        :paymentCurrency="selectedAssignment.paymentCurrency"
      />
    </div>
    <success-dialog
      content="Payment successful"
      :show="showPaymentSuccess"
      :onclose="closeSuccessDialog"
    />
    <v-snackbar
      :timeout="2000"
      style="text-align: center"
      color="orange "
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { driverassignmentList } from "@/constants/datatable-headers.js";
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import ProofOfDeliveryForDriverBooking from "@/components/Pop-ups/ProofOfDeliveryForDriverBooking";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import PaymentDialog from "@/components/Pop-ups/PaymentDialog";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
Vue.use(DateRangePickerPlugin);
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import activityLogs from "../Common/activityLogs";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.countrySortName = key.value;
        }
      });
    }
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "driver-detail-assignments") {
        this.$router.push(`/drivers`);
      }
      this.countrySortName = val.value;
    });
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
    const { id = null } = this.$route.params;
    this.id = id;
  },
  components: {
    DriverProfileForm,
    JobsProfile,
    PaginationButtons,
    SuccessDialog,
    PaymentDialog,
    ProofOfDeliveryForDriverBooking,
  },
  watch: {
    dateRange2(val) {
      if (val[0] && val[1]) {
        this.startDate = moment(val[0]).format("YYYY-MM-DD");
        this.endDate = moment(val[1]).format("YYYY-MM-DD");
      }
      this.payload.skip = "0";
      this.payload.limit = "15";
      this.payload.endDate = this.endDate;
      this.$set(this.payload, "startDate", this.startDate);
    },
  },
  data() {
    return {
      id: null,
      search: "",
      assignId: "",
      countrySortName: "",
      error: null,
      totalPages: 0,
      paymentDialog: false,
      showPaymentSuccess: false,
      loading: false,
      select: [],
      payload: {
        skip: "0",
        limit: "15",
        driverId: this.$route.params.id,
      },
      searchItems: [
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignId" },
      ],
      AssignmentStatus: [
        { status: "Driver Assigned", abbr: 2 },
        {
          status: "Payment Pending",
          abbr: 3,
        },
        { status: "Payment Done", abbr: 4 },
        { status: "In-Transit", abbr: 5 },
        { status: "Shipment Offloaded & POD awaited", abbr: 6 },
        { status: "Job Cancelled", abbr: -1 },
      ],

      headers: driverassignmentList,
      items: [],
      searchTypes: "",
      searchText: "",
      startDate: "",
      endDate: "",
      index: null,
      isNoSearchResult: false,
      waterMark: "Select a Range",
      dateRange2: null,
      loading: false,
      paymentError: null,
      selectedAssignment: null,
    };
  },
  methods: {
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    performSearch() {
      this.getJobById(this.search);
    },
    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime(time) {
      return moment.unix(time).format("ll");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    getJobById(jobId) {
      this.loading = true;
      if (jobId) {
        this.payload = {
          skip: "0",
          limit: "15",
          driverId: this.$route.params.id,
          searchText: jobId,
        };
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          driverId: this.$route.params.id,
        };
      }
    },
    //filter aacording to assignment
    filterByAssignmentStatus() {
      if (this.select.length) {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "assignStatus", this.select);
      } else {
        delete this.payload["assignStatus"];
        this.payload = {
          skip: "0",
          limit: "15",
          driverId: this.$route.params.id,
        };
      }
    },
    reset() {
      this.searchText = "";
      this.searchTypes = "";
      this.payload = {
        skip: "0",
        limit: "15",
        driverId: this.$route.params.id,
      };
      this.dateRange2 = null;
      this.select = [];
    },
    searchByType() {
      this.searchText = "";
      delete this.payload["searchText"];
      if (this.searchTypes) {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "searchType", this.searchTypes.abbr);
      }
    },
    searchAnything() {
      if (this.dateRange2) {
        if (this.dateRange2[0] && this.dateRange2[1]) {
          this.startDate = moment(this.dateRange2[0]).format("YYYY-MM-DD");
          this.endDate = moment(this.dateRange2[1]).format("YYYY-MM-DD");
        }
      }
      if (this.searchText.trim() !== "") {
        if (this.searchText && this.searchTypes) {
          this.payload.skip = "0";
          this.payload.limit = "15";
          this.payload.searchType = this.searchTypes.abbr;
          this.$set(this.payload, "searchText", this.searchText);
        }
      } else if (this.searchText.trim() == "") {
        if (this.select.length && this.dateRange2) {
          this.payload = {
            skip: "0",
            limit: "15",
            searchType: this.searchTypes.abbr,
            driverId: this.$route.params.id,
            assignStatus: this.select,
            startDate: this.startDate,
            endDate: this.endDate,
          };
        } else if (this.select.length) {
          this.payload = {
            skip: "0",
            limit: "15",
            searchType: this.searchTypes.abbr,
            driverId: this.$route.params.id,
            assignStatus: this.select,
          };
        } else if (this.dateRange2) {
          this.payload = {
            skip: "0",
            limit: "15",
            driverId: this.$route.params.id,
            searchType: this.searchTypes.abbr,
            assignStatus: this.select,
            startDate: this.startDate,
            endDate: this.endDate,
          };
        } else {
          this.payload = {
            skip: "0",
            limit: "15",
            driverId: this.$route.params.id,
          };
        }
      }
    },
    getLocation(...val) {
      return val
        ? val
            .filter((val) => val !== undefined)
            .reduce((val, curr) => val + ", " + curr)
        : "";
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.assignId = res.assignId;
      this.$eventBus.$emit("driver-Assignment-Detail", this.assignId);
      this.totalPages = Math.ceil(res.totalData / 15);
    },
    setAssignment(assignment) {
      this.selectedAssignment = assignment;
      this.paymentDialog = true;
    },
    makePayment() {
      if (!this.selectedAssignment) {
        return;
      }
      const { assignmentId } = this.selectedAssignment;
      this.paymentDialog = false;
      this.showPaymentSuccess = true;
      const index = this.items.findIndex(
        (item) => item.assignmentId === assignmentId
      );
      if (index > -1) {
        this.items[index].assignmentStatus = 4;
      }
    },
    closeSuccessDialog() {
      this.showPaymentSuccess = false;
    },
  },
};
</script>

<style lang="scss">
#paymentsTable th {
  background: orange !important;
  font-weight: bold;
}
.inputSearch input[type="number"] {
  -moz-appearance: textfield;
}
.inputSearch input::-webkit-outer-spin-button,
.inputSearch input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.table-header-item1 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.84);
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
.waybill-btn {
  padding: 10px;
  background-color: #ff9800;
  color: #fff;
  text-decoration: none;
}
.m-l-5 {
  margin-left: 5px;
}
.buttonLayout {
  float: left;
  display: block !important;
}
.buttonLayout a {
  float: left;
  width: 100%;
  margin-block-start: 4px;
  margin: 0;
  margin-bottom: 3px;
}
</style>
